import React from "react";
import { Col, Container, Row, } from "reactstrap";

import Blo1Image from "../assets/images/blog/gianinalli.png";
import Blo2Image from "../assets/images/blog/baulives.png";
import Blo3Image from "../assets/images/blog/bizzarro.png";


export default function News() {
    return (
        <>
            {/* Start Blog  */}
            <section className="section bg-light" id="blog">
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Portfolio</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} className="mt-4 pt-2">
                            <div className="card mb-3 blog blog-primary shadow rounded overflow-hidden border-0 shadow-md">
                                <div className="row g-0">
                                    <div className="col-md-4 blog-image position-relative overflow-hidden">
                                        <img src={Blo1Image} className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-8 content p-0">
                                        <div className="p-4">
                                            <h5 className="h5 text-primary d-block mb-0">Baulives - Elevating Design with Italian Expertise</h5>
                                            <p className="text-muted mt-2 mb-0">Immerse yourself in the Italian allure of Baulives' website, a digital masterpiece designed to encapsulate the essence of experience, design prowess, and a relentless drive for innovation. As a dynamic entity dedicated to crafting quality products for both the home and contract design sectors, Baulives' online platform is a testament to the seamless fusion of artistry and functionality.</p>
                                            <span className="d-flex"><i className="uil uil-check-circle align-middle text-primary me-2"></i><p className="text-muted mb-0"><strong>Italian Design Experience Unveiled:</strong> Baulives' website is a journey through Italian design expertise, where every detail speaks of a rich heritage of craftsmanship. The intuitive user interface is a testament to the brand's commitment to providing a captivating and immersive experience.</p></span>
                                            <span className="d-flex"><i className="uil uil-check-circle align-middle text-primary me-2"></i><p className="text-muted mb-0"><strong>A Glimpse into Baulives' Signature Tables:</strong> Explore the distinctive line of tables meticulously crafted for bars, restaurants, and structures within the Ho.Re.Ca sector. The design narrative seamlessly extends to private gardens, transforming outdoor spaces into havens of comfort. These furnishings, designed for maximum outdoor efficiency, effortlessly transition to indoor settings, offering versatility and timeless elegance.</p></span>
                                            <p className="text-muted mt-2 mb-0">Visit baulives.com to witness firsthand how our design team has translated Baulives' vision into a captivating online experience. Baulives' website stands as a digital embodiment of Italian design, innovation, and a commitment to enhancing both home and commercial spaces.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} className="mt-4 pt-2">
                            <div className="card mb-3 blog blog-primary shadow rounded overflow-hidden border-0 shadow-md">
                                <div className="row g-0">
                                    <div className="col-md-8 content p-0">
                                        <div className="p-4">
                                            <h5 className="h5 text-primary d-block mb-0">UNIDEVS - Empowering Businesses with Digital Excellence</h5>
                                            <p className="text-muted mt-2 mb-2">UNIDEVS stands as a trusted partner for SMEs and professionals seeking a dedicated team passionate about exceeding client expectations. With years of experience and a commitment to continuous growth, UNIDEVS specializes in crafting bespoke websites and e-commerce platforms.<br /> Our expertise extends to designing strategic marketing campaigns aimed at achieving our client's business objectives.</p>
                                            <span className="d-flex"><i className="uil uil-check-circle align-middle text-primary me-2"></i><p className="text-muted mb-0"><strong>Our Collaborative Success:</strong> In a delightful collaboration spanning two years, Sudhanshu and the Unidevs team have played a pivotal role in delivering outstanding results. Operating in Italy, we cater to both direct clients and those referred by other agencies lacking Magento developers. Notable projects include e-commerce sites such as lineasportmatera, baulives, and gianinalliarredi. Due to confidentiality agreements, we honor the privacy of other undisclosed projects.</p></span>
                                            <p className="text-muted mt-2 mb-0">Visit our website at unidevs.it to explore the comprehensive range of services that make UNIDEVS a distinguished choice in the digital landscape.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 blog-image position-relative overflow-hidden">
                                        <img src={Blo2Image} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} className="mt-4 pt-2">
                            <div className="card mb-3 blog blog-primary shadow rounded overflow-hidden border-0 shadow-md">
                                <div className="row g-0">
                                    <div className="col-md-4 blog-image position-relative overflow-hidden">
                                        <img src={Blo3Image} className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-8 content p-0">
                                        <div className="p-4">
                                            <h5 className="h5 text-primary d-block mb-0">BIZZARRO - Elevating Digital Experiences with Passion and Precision</h5>
                                            <p className="text-muted mt-2 mb-2">Step into our portfolio to witness the digital brilliance curated by our team of developers for BIZZARRO, a dynamic Mexican brand driven by passion and commitment. Our collaboration seamlessly aligns with BIZZARRO's mission, delivering websites and applications that mirror the brand's dedication to extraordinary quality and beauty.</p>
                                            <span className="d-flex"><i className="uil uil-check-circle align-middle text-primary me-2"></i><p className="text-muted mb-0"><strong>Digital DNA Crafting Unforgettable Online Moments:</strong>
                                                Just as BIZZARRO's physical creations materialize emotions, our digital solutions embody the brand's DNA—synonymous with good taste and innovation. Our websites and applications serve as generators of unforgettable online moments, meticulously designed to reflect the brand's beauty and love. From conceptualization to execution, our team's passion for detail shines through in every pixel.</p></span>
                                                <span className="d-flex"><i className="uil uil-check-circle align-middle text-primary me-2"></i><p className="text-muted mb-0"><strong>Trusted Partnership with Seamless Collaboration:</strong>
                                                Our collaboration with BIZZARRO epitomizes smoothness and responsibility. As a trusted partner, we assure you of a reliable and responsive experience. Our commitment is not just in crafting digital solutions but in fostering a partnership where your visions come to life. With a robust team skilled in Java, Microservices, and certified M2 development, we stand ready to create the next chapter of digital excellence with you.</p></span>
                                            <p className="text-muted mt-2 mb-0">Explore our portfolio to witness the synergy between BIZZARRO's essence and our development prowess. Each project is a testament to our commitment to delivering exceptional and memorable digital experiences.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* End Blog  */}
        </>
    )
}