import React from "react";
import { Col, Container, Row } from "reactstrap";

import Navbar from "../../component/Navbar";
import Feature from "../../component/Feature";
import Pricing from "../../component/Pricing";
import Review from "../../component/Review";
import News from "../../component/News";
import Contact from "../../component/Contact";
import Footer from "../../component/Footer";

import BackgroundImage from '../../assets/images/bg/marketing-shape.png';
import CelebrationImage from '../../assets/images/svg/homepage-1st-section-image.png';
import WordpressImage from '../../assets/images/client/wordpressOne.png';
import ReactjsImage from '../../assets/images/client/reactjsOne.png';
import PhpImage from '../../assets/images/client/phpOne.png';
import MagentoImage from '../../assets/images/client/magentoOne.png';
import LaravelImage from '../../assets/images/client/laravelOne.png';
import JavaImage from '../../assets/images/client/javaOne.png';
import IosImage from '../../assets/images/client/iosOne.png';
import FlutterImage from '../../assets/images/client/flutterOne.png';
import DrupalImage from '../../assets/images/client/drupalOne.png';
import AspImage from '../../assets/images/client/aspOne.png';
import AngularImage from '../../assets/images/client/angularOne.png';
import AndroidImage from '../../assets/images/client/androidOne.png';

/**
 * Marketing component
 */
export default function Marketing() {
    return (
        <>
            <div>
                <Navbar />

                <section className="bg-home bg-soft-primary d-flex align-items-center" style={{ background: `url(${BackgroundImage}) center center`, height: "auto" }} id="home">
                    <Container>
                        <Row className="mt-5 pt-5 justify-content-center">
                            <div className="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                                <div className="title-heading">
                                    <h4 className="heading my-3">Transforming Visions into Seamless Solutions </h4>
                                    <p className="mx-auto text-muted">Your Trusted Partner for Innovative IT Solutions and Design Excellence. We open our digital doors to a world of tailored excellence. Step into a realm where your requirements are not just heard but effectively addressed. Whether you're in search of cutting-edge web development solutions or skilled resources to augment your remote team, our homepage serves as the gateway to your unique aspirations. By choosing to explore our digital domain, you initiate a journey where innovation meets expertise, and your success becomes our shared priority. Welcome to a space where your vision is not only acknowledged but empowered. Let's embark on this transformative journey together!</p>
                                </div>

                                <Row className="justify-content-center">
                                    <Col lg={7} md={10}>
                                        <div className="home-dashboard">
                                            <img src={CelebrationImage} alt="" className="img-fluid" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </Container>
                </section>
                
                <div className="position-relative">
                    <div className="shape marketing-hero overflow-hidden text-light"></div>
                </div>
                <Feature />
                {/* Partners start */}
                <section className="mb-5">
                    <Container>
                        <Row className="row justify-content-center">
                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={WordpressImage}  alt="" />
                            </Col>

                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={ReactjsImage}  alt="" />
                            </Col>

                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={PhpImage}  alt="" />
                            </Col>

                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={MagentoImage}  alt="" />
                            </Col>

                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={LaravelImage}  alt="" />
                            </Col>

                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={JavaImage} alt="" />
                            </Col>
                        </Row>
                        <Row className="row justify-content-center pt-5">
                            

                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={FlutterImage}  alt="" />
                            </Col>

                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={DrupalImage}  alt="" />
                            </Col>
                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={AngularImage}  alt="" />
                            </Col>
                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={AndroidImage}  alt="" />
                            </Col>
                        </Row>
                        <Row className="row justify-content-center pt-5">
                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={AspImage}  alt="" />
                            </Col>
                            <Col lg={2} md={2} className="col-6 text-center py-4 py-sm-0">
                                <img src={IosImage}  alt="" />
                            </Col>
                        </Row>
                    </Container>
                </section>

                
                <Pricing />
                <Review />
                <News />
                <Contact />
                <Footer />

            </div>
        </>
    )
}