import React from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Feature1 from "../assets/images/11.png";
import Feature2 from "../assets/images/mission-vision.png";
import Feature3 from "../assets/images/free-trial.png";
import Feature4 from "../assets/images/contact.png";

export default function Feature() {
    const services = [
        {
            id: 'service1',
            icon: 'airplay',
            title: "UI/UX Design:",
            description: "Elevate your online presence with our expert UI/UX design services. We craft visually appealing and user-centric interfaces to ensure an immersive and engaging digital experience for your audience."
        },
        {
            id: 'service2',
            icon: 'circle-layer',
            title: "CMS Website Development:",
            description: 'Experience seamless content management with our CMS website development. We specialize in creating dynamic and user-friendly websites, with standardized code structures empowering you to easily manage and update your content.'
        },
        {
            id: 'service3',
            icon: 'fire',
            title: "Custom Website Development: ",
            description: 'Tailor-made solutions for your unique needs. Our custom website development services provide fully personalized, scalable, and feature-rich websites that align perfectly with your business objectives.'
        },
        {
            id: 'service4',
            icon: 'flip-h',
            title: "Mobile Application Development:",
            description: 'Stay ahead in the mobile-first era with our mobile application development expertise. Whether iOS or Android, we create innovative and functional mobile apps that cater to your specific audience.'
        }, {
            id: 'service5',
            icon: 'mountains',
            title: "Dedicated Remote Teams:",
            description: 'Unlock the benefits of dedicated expertise with our remote teams. Our skilled professionals work exclusively for you, offering a flexible and efficient extension to remote teams for successful project.'
        }, {
            id: 'service6',
            icon: 'wind',
            title: "B2C/B2B Approaches ",
            description: "Introducing Business to Everyone (B2E) – a dynamic strategy designed to embrace emerging channels and business models, catering to the elevated expectations of today's discerning buyers."
        },
    ]

    return (
        <>
            {/*  Services START  */}
            <section className="section" id="features">
                <Container className="mt-100 mt-60">
                    <Row>
                        <Col lg={5}>
                            <div className="section-title">
                                <h4 className="section-title mb-3 mb-lg-0">Our Development and Outsourcing Transformative Solutions Journey:</h4>
                            </div>
                        </Col>

                        <Col lg={7}>
                            <p className="text-muted para-desc mb-0">Exploring Our Mission and Vision for Transformative Solutions and Global Excellence. Embark through our Development and Outsourcing narrative, where every chapter is marked by a commitment to innovation and global excellence. At Think More IT Solutions, we invite you to delve into the values of our mission and vision.</p>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col lg={6} md={6}>
                            <img src={Feature1} className="img-fluid shadow-md rounded-md" alt="" />
                        </Col>

                        <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">Welcome to Think More IT Solutions</h4>
                                <p className="text-muted">A renowned website development and outsourcing services company established in March 2022. At TMIT, we are driven by a commitment to deliver world-class products, services, and business solutions tailored to meet the unique needs of our clients.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Client Satisfaction Beyond Transactions</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Nurturing a Positive Work Environment</li>
                                </ul>
                                <Link to="mailto:info@thinkmoreitsolutions.com" className="mt-3 text-primary">Connect with Us <i className="uil uil-angle-right-b"></i></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col lg={6} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title me-lg-5">
                                <h4 className="title mb-4">Our Mission & Vision:</h4>
                                <p className="text-muted">At Think More IT Solutions, our mission is to emerge as the premier IT services provider in India. We strive to achieve this by consistently delivering creative, innovative solutions and services that add tangible value to our clients' businesses, providing them with a competitive advantage in the market. Our vision encompasses fostering growth, both for our clients and our company, as we continue to set new benchmarks in the realm of IT services.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Pioneering IT Excellence</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Fostering Growth and Setting Benchmarks:</li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={6} md={6} className="order-1 order-md-2">
                            <img src={Feature2} className="img-fluid shadow-md rounded-md" alt="" />
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h3 className="title mb-3 mt-5">Our Comprehensive Suite of Services</h3>
                                <p className="text-muted mx-auto para-desc mb-0">Elevate Your Digital Presence with Our Expertise</p>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        {services.map((item, key) => (
                            <Col lg={4} md={6} className="mt-5 pt-4" key={key}>
                                <Card className="features feature-primary feature-shadow-md shadow rounded p-4 pt-5">
                                    <div className="icon-style mt-n80 position-relative text-center shadow rounded bg-white mb-4">
                                        <i className={"h4 mb-0 uil uil-" + item.icon}></i>
                                    </div>
                                    <div className="content">
                                        <Link to="#" className="title text-dark h5">{item.title}</Link>
                                        <p className="text-muted mt-3">{item.description}</p>

                                    </div>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row>
                        <Col lg={5}>
                            <div className="section-title">
                                <h4 className="section-title mb-3 mb-lg-0">Explore Our Services with a Free Trial </h4>
                            </div>
                        </Col>

                        <Col lg={7}>
                            <p className="text-muted para-desc mb-0">Embark on a journey of innovation and efficiency with our trial services. Whether you opt for our 20-hour trial or the extended 2-day trial, it's an opportunity to experience our skill set firsthand.</p>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col lg={6} md={6}>
                            <img src={Feature3} className="img-fluid shadow-md rounded-md" alt="" />
                        </Col>

                        <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">Why Choose Our Free Trial Services?</h4>

                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span><strong>Test Our Skill Set:</strong></li>
                                    <p className="text-muted">Dive into our services to assess the depth of our expertise. Evaluate the quality and proficiency of our solutions tailored to meet your unique requirements.</p>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span><strong>Uncover Potential Benefits:</strong></li>
                                    <p className="text-muted">The trial period allows you to explore the potential benefits our services can bring to your projects. Witness the impact of our solutions on your business objectives.</p>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span><strong>Risk-Free Engagement:</strong></li>
                                    <p className="text-muted">Take advantage of our trial services with no obligation. It's a risk-free opportunity to understand how our offerings align with your needs before making a commitment.</p>
                                </ul>

                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col lg={6} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title me-lg-5">
                                <h4 className="title mb-4">Connect with Our Sales Team for Tailored Solutions</h4>
                                <p className="text-muted">Seize this chance to elevate your projects and witness the difference our services can make. Contact to initiate your free trial and explore the possibilities with Think More IT Solutions.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Phone: +91-7737196277</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Email: info@thinkmoreitsolutions.com</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Skype: Sudhanshu.Vijayvargiya</li>
                                </ul>

                            </div>
                        </Col>

                        <Col lg={6} md={6} className="order-1 order-md-2">
                            <img src={Feature4} className="img-fluid shadow-md rounded-md" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
};