import React from "react";
import TinySlider from "tiny-slider-react";
import { Col, Container, Row } from "reactstrap";

import avatar1 from "../assets/images/client/digileap.png";
import avatar2 from "../assets/images/client/unidevs.png";
import avatar3 from "../assets/images/client/petcenter.png";
import avatar4 from "../assets/images/client/bizzarro.png";


import Map from "../assets/images/map.png";
import 'tiny-slider/dist/tiny-slider.css';

const settings = {
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
        0: {
            items: 1
        },

        767: {
            items: 2
        },

        992: {
            items: 2
        },
    },
};

export default function Review() {
    const review = [
        {
            id: '1',
            profile: avatar1,
            name: 'Digileap',
            designation: "UK",
            description: "Working with Think More IT Solutions has been a pleasure. As the founder of Digileap operating in the UK, Jordan, and Saudi Arabia, I've experienced a seamless collaboration since February 2023. Sudhanshu, VP-Sales, has demonstrated responsibility, immediate support, and a dedicated Java & React.js developers' team, making him a trusted partner. We've hired 3 dedicated developers and confidently recommend Sudhanshu for his excellent service and a skilled development team. "
        },
        {
            id: '2',
            profile: avatar2,
            name: 'Unidevs',
            designation: "Italy",
            description: "Delighted to connect with Sudhanshu, I'm the founder of Unidevs, an Italian web agency. Sudhanshu has been a valuable collaborator for 2 years, contributing to successful e-commerce projects like lineasportmatera, baulives, and gianinalliarredi. The partnership has been excellent, handling direct clients and supporting agencies lacking Magento devs in their team. "
        }, {
            id: '3',
            profile: avatar3,
            name: 'Petcenter',
            designation: "Switzerland ",
            description: "Think More IT Solutions have been instrumental in our digital transformation projects. Their expertise in Java and Microservices, coupled with Sudhanshu's proactive communication, has made our collaboration seamless. Looking forward to more successful ventures together!"
        }, {
            id: '4',
            profile: avatar4,
            name: 'Bizzario',
            designation: "Mexico",
            description: "I'm thrilled to acknowledge Sudhanshu's exceptional services. Our partnership with Sudhanshu's team has exceeded expectations. Not only did they deliver cutting-edge solutions, but Sudhanshu's hands-on approach and dedication to client satisfaction have truly set them apart. Highly recommended!"
        },
        
    ]

    return (
        <>
            {/* Review Start  */}
            <section className="section" id="review">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Client Success Stories</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Discover the Journeys of Satisfaction and Success Shared by Our Valued Clients.</p>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-12 mt-4">
                            <div className="tiny-three-item">
                                <TinySlider settings={settings} className="tiny-slide">
                                    {review.map((el, index) => (
                                        <div className="customer-testi m-1" key={index}>
                                            <div className="content p-3 shadow rounded bg-white position-relative">
                                                <ul className="list-unstyled mb-0 text-warning">
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star"></i></li>
                                                </ul>
                                                <p>{el.description}</p>
                                            </div>
                                            <div className="text-center mt-3">
                                                <img src={el.profile} className="avatar avatar-small rounded shadow" alt="" />
                                                <p className="text-primary mt-3 mb-0">{el.name} <small className="text-muted d-block ms-2">{el.designation}</small></p>
                                            </div>
                                        </div>
                                    ))}
                                </TinySlider>
                            </div>
                        </div>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60 d-none">
                    <div className="py-5 px-4 bg-soft-primary rounded-lg" style={{
                        background: `url('${Map}') center center`
                    }}>
                        <Row className="justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h4 className="title mb-4">Subscribe our Newsletter</h4>
                                    <p className="text-muted mx-auto para-desc mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap5 html page.</p>
                                </div>
                            </div>

                            <Col lg={8}>
                                <div className="text-center subcribe-form mt-4 pt-2">
                                    <form>
                                        <div className="form-group mb-0">
                                            <input type="email" id="email2" name="email" className="rounded-pill" placeholder="Your Email Id" />
                                            <button type="submit" className="btn btn-pills btn-primary">Subscribe Now</button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </section>
        </>
    )
}
