import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ContactUsImage from "../assets/images/thinkmoresolution/tm-contact-us.jpg";

export default function Contact() {
    return (
        <>
            {/* Start Contact  */}
            <section className="section" id="contact">
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <div className="section-title text-center mb-4">
                                <h4 className="title mb-3">Let's Connect!</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Reach Out for Expert Web Development Solutions and Collaborative Excellence.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center border">
                        <Col lg={8} md={6} className="order-md-2 order-1 mt-4 pt-2">
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-8">
                                        <div className="card-body pt-0">
                                            <p className="card-text">We invite you to reach out with your requirements, and we'll guide you toward the best solutions and consultancies. Whether you're seeking expert web development solutions or skilled, experienced resources for your remote team, we have you covered.</p>
                                            <p className="card-text">Our team is dedicated to providing tailored solutions that align precisely with your requirements. From innovative web development to assembling skilled remote teams, our expertise is your gateway to excellence.</p>
                                            <p className="card-text">Reach us with the specifics of your project. Whether it's shaping a cutting-edge web solution or enhancing your remote team capabilities, we are here to assist you at every step. Your success is our priority, and by connecting with us, you initiate a partnership built on innovation, expertise, and a shared commitment to achieving your goals.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={ContactUsImage} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
                            <div className="me-lg-4">
                                <div className="d-flex">
                                    <div className="icons text-center mx-auto">
                                        <Link to="tel:+91-7737196277" className="text-muted"><i className="uil uil-phone d-block rounded h4 mb-0"></i></Link>
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2">Phone</h5>
                                        <p className="text-muted mb-2">+91-7737196277</p>
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <Link to="mailto:info@thinkmoreitsolutions.com" className="text-muted"><i className="uil uil-envelope d-block rounded h4 mb-0"></i></Link>
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2">Email</h5>
                                        <p className="text-muted mb-2">info@thinkmoreitsolutions.com</p>
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <Link to="https://maps.app.goo.gl/SdeaxDhPm66oQzBb8" className="text-muted"><i className="uil uil-map-marker d-block rounded h4 mb-0"></i></Link>
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2">Location</h5>
                                        <p className="text-muted mb-2">9, Dev Nagar ,B Niwaru, jaipur, Rajasthan, 302012</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* End Contact  */}
        </>
    )
}