import React, { useState, useEffect } from 'react';
import { Col, Container, Card, Row } from 'reactstrap';
import { Link } from "react-router-dom";
import { Link as Link2 } from 'react-scroll';
import * as Icon from 'react-feather';
import Logo from "../assets/images/footer-logo.png";

export default function Footer() {

    const [arrow, setArrow] = useState(false);

    const handleScroll = () => {
        if (
            document.body.scrollTop >= 500 ||
            document.documentElement.scrollTop >= 500
        ) {
            setArrow(true);
        } else {
            setArrow(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            {/* Back to top */}
            <Link2 to="home" style={{ display: arrow === true ? 'block' : 'none' }} id="back-to-top" className="back-to-top rounded-pill fs-5"><Icon.ArrowUp className="fea icon-sm icons align-middle" /></Link2>
            {/* Back to top  */}

            {/* Footer Start  */}
            <footer className="bg-footer">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="footer-py-60 text-center">
                                <Row className="py-5">
                                    <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <Card className="border-0 text-center features feature-primary bg-transparent">
                                            <div className="feature-icon text-center mx-auto">
                                                <i className="uil uil-envelope rounded h4 mb-0"></i>
                                            </div>
                                            <div className="content mt-4">
                                                <h5 className="footer-head">Email</h5>
                                                <p className="text-muted"></p>
                                                <Link to="mailto:info@thinkmoreitsolutions.com" className="text-foot">info@thinkmoreitsolutions.com</Link>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col md={4} >
                                        <Card className="border-0 text-center features feature-primary bg-transparent">
                                            <div className="feature-icon text-center mx-auto">
                                                <i className="uil uil-phone rounded h4 mb-0"></i>
                                            </div>
                                            <div className="content mt-4">
                                                <h5 className="footer-head">Phone</h5>
                                                <p className="text-muted"></p>
                                                <Link to="tel:+91-7737196277" className="text-foot">+91-7737196277</Link>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <Card className="border-0 text-center features feature-primary bg-transparent">
                                            <div className="feature-icon text-center mx-auto">
                                                <i className="uil uil-map-marker rounded h4 mb-0"></i>
                                            </div>
                                            <div className="content mt-4">
                                                <h5 className="footer-head">Location</h5>
                                                <p className="text-muted">9, Dev Nagar ,B Niwaru,Jaipur, RJ, 302012</p>
                                                <Link to="https://maps.app.goo.gl/SdeaxDhPm66oQzBb8"
                                                    data-type="iframe" className="video-play-icon text-foot lightbox"></Link>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Row>
                </Container>

                <div className="footer-py-30 footer-bar bg-footer">
                    <Container className="text-center">
                        <Row className="align-items-center justify-content-between">
                            <Col lg={6} md={6} sm={6}>
                                <div className="text-sm-start">
                                    <Link to="#" className="logo-footer">
                                        <img src={Logo} alt="" />
                                    </Link>
                                </div>
                            </Col>



                            <Col lg={6} md={6} sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="text-sm-end">
                                    <p className="mb-0 text-foot">Copyright © {(new Date().getFullYear())}{" "} <Link to="#" className="text-reset">- Think More IT Solution. All Rights Reserved</Link>.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
            {/* Footer End  */}
        </>
    )

};