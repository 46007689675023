import React, { Component } from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";
import testing from "../assets/images/client/testing.png";
import planning from "../assets/images/client/planning.png";
import launch from "../assets/images/client/launch.png";
import development from "../assets/images/client/development.png";
import design from "../assets/images/client/design.png";
import requirments from "../assets/images/client/requirments.png";

export default function Pricing() {
    const pricing = [
        {
            id: 1,
            profileOne: requirments,
            title: 'Requirements',
            description: "By engaging in thorough discussions and active listening, we aim to understand our clients' unique needs and objectives. A tailored and effective solution, ensuring a seamless alignment between our client's vision and our strategic approach."
        },
        {
            id: 2,
            profileOne: planning,
            title: 'PLANNING',
            description: 'In this initial phase, we collaborate closely with you to understand your vision and requirements. We meticulously plan the project, defining objectives, timelines, Scope of Work and key milestones to ensure a clear roadmap.'
        },
        {
            id: 3,
            profileOne: design,
            title: 'Design',
            description: 'Leveraging our expertise in design, we create detailed outlines and blueprints that serve as the foundation for your project. Our design phase is focused on ensuring a user-centric and visually appealing end product.'
        },
        {
            id: 4,
            profileOne: development,
            title: 'DEVELOPMENT',
            description: 'Our team of professional developers employs industry-best practices to reshape your concepts into functional and robust solutions. We prioritize coding standards and scalability to deliver a product that stands the test of time.'
        },
        {
            id: 5,
            profileOne: testing,
            title: 'Testing ',
            description: 'Quality assurance is integral to our process. Rigorous testing is conducted to identify and rectify any potential issues, ensuring that the final product meets the highest standards of functionality, security, and performance.'
        },
        {
            id: 6,
            profileOne: launch,
            title: 'LAUNCH',
            description: 'With thorough planning, design, development, and testing complete, we move to the exciting stage of launching your product. Our approach ensures a smooth and successful deployment into the real-world environment.'
        }
    ]

    return (
        <>
            {/* Start Pricing  */}
            <section className="section bg-light" id="pricing">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title fw-semibold mb-4">Our Workflow of Transforming Ideas into Reality </h4>
                                <p className=" text-muted mx-auto mb-0">At Think More IT Solutions, our commitment to delivering cutting-edge solutions is reflected in our meticulous and structured development process. Guided by a team of seasoned professionals, we follow a step-by-step methodology to bring your innovative ideas to life. Our comprehensive working process encompasses the following key stages:</p>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        {pricing.map((item, key) => (
                            <Col lg={4} md={6} className="col-12 mt-4 pt-2" key={key}>
                                <Card className="border-0 shadow features feature-shadow-md">
                                    <div className="text-center">
                                        <img src={item.profileOne} className="card-img-top" style={{ height: '100px', width: '100px' }} alt="" />
                                    </div>
                                    <div className="p-4 border-bottom border-light">
                                        <h6 className="fw-semibold mb-3 text-uppercase text-center text-primary">{item.title}</h6>
                                        <p className="text-muted mb-0">{item.description}</p>
                                        <div className="d-flex my-4 d-none">
                                            <span className="price h3 fw-semibold mb-0">{item.price}</span>
                                            <span className="text-muted align-self-end mb-1"></span>
                                        </div>
                                    </div>
                                </Card>
                            </Col>

                        ))}

                        <div className="col text-center mt-2">
                            <p className="mt-3 mb-0 text-muted">This structured working process underscores our commitment to excellence, transparency, and client satisfaction. By adhering to these steps, we aim not only to meet but exceed your expectations, delivering innovative solutions that make a tangible impact in the digital landscape.</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}